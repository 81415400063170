@import 'src/styles/config';

.wrap {
  display: grid;
  margin-left: -1.6rem;
}
.drawer {
  width: 28rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}
.top {
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
  align-items: center;
  grid-gap: 0.8rem;
  box-shadow: $box-shadow-dark-blue;
  height: $header-height;
  padding: 0 1.6rem 0 0;
}
.middle {
  overflow: auto;
}
.bottom {
  padding: 1rem 1.6rem;
  box-shadow: $box-shadow-dark-blue;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
}
