@import './config/functions';
@import './config/breakpoints';
@import './config/colors';
@import './config/mixins';
@import './config/variables';
@import './config/placeholders';

:export {
  colorPrimary: $color-primary;
  colorPrimaryLight: $color-primary-light;
  colorSecondary: $color-secondary;
  colorSecondaryLight: $color-secondary-light;
  colorSecondaryLightest: $color-secondary-lightest;
  colorMain: $color-main;
  colorMainLight: $color-main-light;
  colorGrey: $color-grey;
  colorGreyLight: $color-grey-light;
  colorGreyDark: $color-grey-dark;
  colorGreyDarkLight: $color-grey-dark-light;
  boxShadowDarkBlue: $box-shadow-dark-blue;
  colorSilver: $color-silver;
}

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}
