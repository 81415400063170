.wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  background: #f4f6f8;
  flex-grow: 1;
  min-height: 100%;
  & > * {
    min-width: 0;
  }
}
