@import 'src/styles/config';

.wrap {
  min-height: 100%;
  display: grid;
  position: relative;
  z-index: 1;
  grid-template-areas: 'header content' 'footer footer';
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  flex-grow: 1;
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    bottom: 0;
    background: #f4f6f8 url('../../images/bg-login-page.png') no-repeat 100% 0;
    background-size: cover;
    z-index: -1;
  }
}

.header {
  grid-area: header;
  padding: 3rem;
  display: grid;
  align-items: center;
}
.content {
  grid-area: content;
  padding: 0 12rem;
  display: grid;
  align-items: center;
}

.footer {
  grid-area: footer;
}

.logo {
  width: 27.8rem;
  margin: 0 auto 7.5rem;
  img {
    width: 100%;
  }
}
.form {
  max-width: 38.6rem;
  width: 100%;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.copy {
  text-align: right;
  padding: 2rem 3rem;
  width: 50%;
  font-size: 1.2rem;
  color: #999;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .content {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .wrap {
    grid-template-areas: 'header' 'content' 'footer';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    &::before {
      display: none;
    }
  }
  .header {
    background: #f4f6f8 url('../../images/bg-login-page.png') no-repeat 100% 0;
    padding: 1.6rem;
  }
  .logo {
    margin: 0 auto;
  }
  .content {
    padding: 3.2rem;
  }
  .form {
    margin: 0 auto;
  }
  .footer {
    background: #fafbfc;
  }
  .copy {
    width: 100%;
    padding: 1.6rem;
    text-align: center;
  }
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .logo {
    width: 18rem;
  }
}
