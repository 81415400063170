@import 'src/styles/config';

.wrap {
}

.item {
  min-height: $header-height;
  color: rgba($color-secondary, 0.5);
  font-weight: normal;
  transition: 0.3s;
  &:global {
    &.Mui-selected {
      color: $color-secondary;
    }
  }
  &Vertical {
    &:global {
      &.Mui-selected {
        background-color: $color-primary;
      }
    }
  }
}

.indicator {
  height: 0.3rem;
  &Vertical {
    display: none;
  }
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .item {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}
