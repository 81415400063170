@import 'src/styles/config';
.wrap {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.text {
  color: $color-grey-light;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .wrap {
    text-align: center;
  }
}
