$color-main: #333;
$color-main-light: rgba($color-main, 0.5);

$color-primary: #5fc0eb;
$color-primary-light: rgba($color-primary, 0.5);

$color-secondary: #21526c;
$color-secondary-light: rgba($color-secondary, 0.5);
$color-secondary-lightest: rgba($color-secondary, 0.3);

$color-grey: #e9eef0;
$color-grey-light: #999;
$color-grey-dark: #333;
$color-grey-dark-light: rgba($color-grey-dark, 0.5);
$color-silver: #f4f6f8;
