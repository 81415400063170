@import './config';
@import './data-grid';

@import "./libs/react-image-lightbox";

html {
  font-size: 62.5%;
  min-height: calc(var(--vh, 1vh) * 100);
  height: 100%;
  overflow: hidden;
}

body,
#root {
  height: 100%;
}
#root {
  display: flex;
  flex-direction: column;
}

body {
  color: $color-main;
  font-size: 1.4rem;
  font-family: Arial, Tahoma, Geneva, sans-serif;
}
.container {
  width: 100%;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  margin-right: auto;
  margin-left: auto;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3rem #fff inset !important;
  -webkit-text-fill-color: #000 !important;
}

@media (max-width: map-get($breakpoints, 'xl') - 1 + px) {
  .container {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}
