@import 'src/styles/config';

.wrap {
  background: #fff;
  box-shadow: $box-shadow-dark-blue;
  position: sticky;
  top: 0;
  z-index: 20;
}
.inner {
  display: grid;
  grid-template-columns: 10.5rem 1fr auto;
  grid-gap: 2rem;
  min-height: $header-height;
}
.logo {
  align-self: center;
}
.data {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 1rem;
}
.logout {
  display: grid;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .inner {
    grid-gap: 0.8rem;
  }
  .data {
    grid-gap: 0;
  }
  .logout {
    margin-right: -1.6rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .inner {
    grid-template-columns: auto 1fr auto;
  }
  .data {
    grid-template-columns: repeat(3, auto);
  }
}
