@import 'src/styles/config';

.wrap {
  color: $color-secondary;
}

.box {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 1rem;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .box {
    grid-template-columns: 1fr;
  }
}
