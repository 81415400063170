@import 'src/styles/config';

.wrap {
  padding: 0;
  display: grid;
  align-items: center;
}
.item {
  color: $color-secondary;
  line-height: 1.14;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  &Title {
  }
  &Icon {
    min-width: unset;
    color: $color-secondary-light;
    margin: 0 -1rem 0 0.5rem;
    &Open {
      transform: rotate(-180deg);
    }
  }
}
